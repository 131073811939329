/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, ContactForm, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1h9zv1 --style2 --full pb--60 pt--60" name={"hero"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f1571d86a2824de1ab5fdd4b244e246d_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Nezávazná poptávka"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--0 pb--60 pt--60" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)","maxWidth":660}} content={"Velké, ale ani menší akce pro nás nejsou překážkou.\n<br>Řekněte nám, jaký event chystáte a my už si poradíme!"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Vaše jméno:"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"E-mailová adresa:"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon:"},{"name":"Charakter akce","type":"text","placeholder":"Charakter akce:"},{"name":"Počet hostů","type":"text","required":true,"placeholder":"Počet hostů:"},{"name":"Datum konání","type":"text","required":true,"placeholder":"Datum konání:"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Napište nám více informací o vašem eventu:"},{"name":"Odeslat","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: bold;\">Royal Catering s.r.o.\n ​<br></span>Rybná 716/24, Staré Město, 110 00 Praha\n<br>IČO: 06722857 | DIČ: CZ06722857</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: bold;\">Kontaktní osoba:\n<br></span>Tomáš Blaha: +420 723 101 012\n<br>Jan Zeman: +420 777 009 561</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"dj305of56b4"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/42c3f9456a28421fbf4ff721815df564_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":587}} srcSet={"https://cdn.swbpg.com/t/18947/42c3f9456a28421fbf4ff721815df564_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/42c3f9456a28421fbf4ff721815df564_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/42c3f9456a28421fbf4ff721815df564_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/42c3f9456a28421fbf4ff721815df564_s=1400x_.png 1400w, https://cdn.swbpg.com/t/18947/42c3f9456a28421fbf4ff721815df564_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":215}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":123}} srcSet={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">E-mail<br></span><br>info@olivacatering.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">Telefon<br></span><br>+420 723 101 012</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700; color: rgb(255, 255, 255);\">Sledujte nás</span><br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<a href=\"https://www.instagram.com/olivacatering_cz/\" style=\"color: rgb(255, 255, 255);\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}